<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary">eWork</h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
         <b-overlay :show="loading" rounded="sm">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Modification de mot de passe
          </b-card-title>
          <b-card-text class="mb-2">
            Veuillez saisir un nouveau mot de passe.
          </b-card-text>

          <!-- form -->
          <validation-observer ref="passwordChange" #default="{ invalid }">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="changeMyPassword"
            >
              <!-- password -->
              <b-form-group label-for="register-password" label="Mot de passe">
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required|password|min:8"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="register-password"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      :type="passwordFieldType"
                      v-model="myPassword.password"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="confirm-password"
                label="Confirmer le mot de passe"
              >
                <validation-provider
                  #default="{ errors }"
                  name="password_confirmation"
                  rules="confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="confirm-password"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="password_confirmation"
                      :type="passwordFieldType"
                      v-model="myPassword.confirmPassword"
                      placeholder="············"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Valider
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ArrowLeftIcon" /> Se connecter
            </b-link>
          </p>
         </b-overlay>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@/@core/layouts/components/logo.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapActions } from "vuex";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      myPassword: {},
      loading: false,
      accountEmail: localStorage.getItem("accountEmail"),
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },

    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    changeMyPassword() {
      this.$refs.passwordChange.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.myPassword.email = this.accountEmail;
          this.changePassword(this.$formData(this.myPassword))
            .then((r) => {
              localStorage.setItem("accountEmail", this.myPassword.email);
              this.loading = false;
              this.$router.replace("/login");
            })
            .catch((error) => {
              this.loading = false;
              this.errorMessage = error.message;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Code invalide",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },

    checkPassword() {
      this.user.password !== this.user.confirmPassword
        ? (this.confirm_password_error =
            "Les deux mots de passe ne sont pas identiques")
        : (this.confirm_password_error = null);
    },

    // returnPassword() {
    //   this.$refs.simpleRules.validate().then(success => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Demande envoyée',
    //           icon: 'EditIcon',
    //           variant: 'success',
    //         },
    //       })
    //     }
    //   })
    // },
    ...mapActions("auth", ["changePassword"]),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
